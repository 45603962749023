import React, { useState } from 'react';
import { FeedbackIcon } from '../../assets/icons/FeedbackIcon';
import { ZapierBtn, ZapierChat } from './styles';
import { PlusIcon } from '../../assets/icons/PlusIcon';

export const ZapierPopup = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {open && (
        <ZapierChat>
          <div className="chat-widget__header">
            <div
              className="chat-widget__header-close"
              onClick={() => setOpen(!open)}
            >
              <PlusIcon width={20} height={20} color="#ccc" />
            </div>
          </div>
          <div
            className="chat-body"
            dangerouslySetInnerHTML={{
              __html: `<zapier-interfaces-chatbot-embed 
                        is-popup='false' 
                        chatbot-id='cm49o7vgc0008jwpo3pe67kp1' 
                        height='100%' 
                        width='100%'>
                        </zapier-interfaces-chatbot-embed>`,
            }}
          />
        </ZapierChat>
      )}
      <ZapierBtn
        onClick={() => {
          setOpen(!open);
        }}
      >
        <FeedbackIcon />
      </ZapierBtn>
    </>
  );
};
