import styled from 'styled-components';

export const ZapierBtn = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: #0000ff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: shake 3s infinite ease-in-out;
  cursor: pointer;
  z-index: 999;

  @media (max-width: 768px) {
    bottom: 70px;
    right: 10px;
  }
`;

export const ZapierChat = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 500px;
  background: rgba(22, 22, 20, 0.6);
  border: 0.5px solid #343937;
  padding: 15px;
  border-radius: 10px;
  z-index: 9999;
  right: 75px;
  bottom: 75px;
  gap: 10px;
  box-sizing: border-box;

  @media (max-width: 479px) {
    width: 100%;
    height: 100%;
    background: rgba(22, 22, 20, 1);
    right: 0;
    bottom: 0;
  }

  .chat-body {
    width: 100%;
    height: 100%;

    zapier-interfaces-chatbot-embed {
      width: 100%;
      height: 100%;
    }
  }

  .chat-widget {
    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      &-close {
        position: relative;
        transform: rotate(45deg);
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
`;
